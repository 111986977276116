<template>
    <div class="json-editor">
      <textarea ref="textarea" />
    </div>
  </template>
  
  <script>
  import CodeMirror from 'codemirror'
  import 'codemirror/addon/lint/lint.css'
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/rubyblue.css'
  require('script-loader!jsonlint')
  import 'codemirror/mode/javascript/javascript'
  import 'codemirror/addon/lint/lint'
  import 'codemirror/addon/lint/json-lint'
  
  export default {
    name: 'JsonEditor',
    /* eslint-disable vue/require-prop-types */
    props: ['value'],
    data() {
      return {
        jsonEditor: false
      }
    },
    watch: {
      value(value) {
        const editorValue = this.jsonEditor.getValue()
        console.log("editorValue",editorValue)
        if (value !== editorValue) {
          this.jsonEditor.setValue(this.value)
        }
      }
    },
    mounted() {
      this.jsonEditor = CodeMirror.fromTextArea(this.$refs.textarea, {
        lineNumbers: true,
        mode: 'application/json',
        gutters: ['CodeMirror-lint-markers'],
        theme: 'dracula',
        // lint: true
      })
      this.jsonEditor.setSize('100%',130);
      if(this.value) {
          this.jsonEditor.setValue(this.value)
      }
      this.jsonEditor.on('change', cm => {
        this.$emit('change', cm.getValue())
      })
    },
    methods: {
      getValue() {
        console.log(11111)
        return this.jsonEditor.getValue()
      }
    }
  }
  </script>
  
  <style lang="less">
  .json-editor {
    height: 100%;
    width: 100%;
    margin-left: -36px;
    position: relative;
  
    ::v-deep {
      .CodeMirror {
        height: auto;
        min-height: 130px;
      }
  
      .CodeMirror-scroll {
        min-height: 130px;
      }
  
      .cm-s-rubyblue span.cm-string {
        color: #F08047;
      }
    }
  }
  @media screen and (max-width: 768px) {
      .json-editor {
        margin-left: -36px;
        width: calc(100% + 36px);
      }
  }
  </style>