<template>
  <div class="transfer_content">
    <div class="desc_box">
      <div class="title">快捷向多個地址發起轉賬</div>
      <div class="desc_item desc_act">
        <img src="../assets/img/tr1.png" class="icon active" srcset="" />
        <div>
          <div class="desc_title">開始批量轉賬</div>
          <div class="desc_text">選擇公鏈资产后批量手动/自动导入目标地址</div>
        </div>
      </div>
      <div class="desc_item" :class="{ desc_act: step > 0 }">
        <img src="../assets/img/tr2.png" class="icon normal" srcset="" />
        <img src="../assets/img/tra2.png" class="icon active" srcset="" />
        <div>
          <div class="desc_title">確認信息</div>
          <div class="desc_text">二次確認填写的地址 & 转账數量</div>
        </div>
      </div>
      <div class="desc_item" :class="{ desc_act2: step > 1 }">
        <img src="../assets/img/tr3.png" class="icon normal" srcset="" />
        <img src="../assets/img/tra3.png" class="icon active" srcset="" />
        <div>
          <div class="desc_title">發送成功</div>
          <div class="desc_text">等待区块確認后即成功</div>
        </div>
      </div>
      <div class="desc_bg"></div>
    </div>
    <div class="main_box" v-show="step == 0">
      <my-scroll>
      <div class="base_title base_title_wallet">選擇公鏈</div>
      <div class="base_select mt20" :class="{ select_active: show1 }">
        <div class="content">
          <img
            :src="chainObj[selectChain].logo"
            class="select_img"
            srcset=""
          />{{ chainObj[selectChain].chainName }}
        </div>
        <div class="right_down" @click.stop="show1 = !show1,show3=false"></div>
        <div class="downlist" v-click-outside="handleScroll">
          <div
            class="list_item"
            v-for="(item, index) in chains"
            :key="index"
            @click="switchChain(item)"
          >
            <img :src="item.logo" class="select_img" srcset="" />{{
              item.chainName
            }}
          </div>
        </div>
      </div>
      <div class="both_side">
        <div class="base_title base_title_wallet">發送代幣</div>
        <img src="../assets/img/contract.png" class="contract_img" srcset="" />
      </div>
      <div class="base_input_select mt40" :class="{ select_active: show3 }">
        <input
          type="text"
          placeholder="請選擇代幣或粘貼代幣合約地址"
          v-model="selectToken.Symbol"
          @input="addressInput"
        />
        <div class="right_down" @click.stop="show3 = !show3,show1=false"></div>
        <div class="downlist" v-click-outside="handleScroll">
          <div
            class="list_item"
            v-for="(item, index) in Tokens"
            @click="selToken(index)"
            :key="item.Symbol"
          >
            <!-- <div class="content">
              {{ item.Symbol }}
              <div class="name">{{ item.address }}</div>
            </div> -->
            <div class="align_center">
              <img
                v-if="item.logo"
                :src="item.logo"
                class="select_img"
                alt=""
                srcset=""
              />
              <div class="content">
                {{ item.Symbol }}
                <div class="name">{{ item.address }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="switch_box mt40">
        <span class="name" style="color: rgba(33, 33, 33, 0.5)"
          >通貨緊縮代幣</span
        >
        <Tooltip max-width="300" content="通貨緊縮代幣" placement="top">
          <img src="../assets/img/quest2.png" class="quest" srcset />
        </Tooltip>
        <i-switch size="large" v-model="status" />
      </div> -->
      <div class="both_side">
        <div class="base_title base_title_wallet">接收地址和數量</div>
      </div>

      <div class="col_box">
        <div class="both_side">
          <div class="tabs_box">
            <div
              class="tabs_item"
              @click="stepTabIndex = 0"
              :class="{ tabs_act: stepTabIndex == 0 }"
            >
              手动輸入
            </div>
            <div
              class="tabs_item"
              @click="stepTabIndex = 1"
              :class="{ tabs_act: stepTabIndex == 1 }"
            >
              上傳文件
            </div>
          </div>
          <div class="btn_link" @click="showModal">自動添加數量</div>
        </div>
        <div v-show="stepTabIndex == 0" class="tab_input_ctn">
          <div class="input_label">批量粘貼收款地址時以逗號為間隔</div>
          <div class="card_inner" style="position: relative; height: auto">
            <json-editor
              ref="jsonEditor"
              v-model="jsonValue"
              @change="handleJsonChange"
            />
          </div>
        </div>
        <div
          class="both_side"
          style="margin-top: 10px"
          v-show="stepTabIndex == 0"
        >
          <div></div>
          <div class="btn_link" @click="uploadData">查看例子</div>
        </div>
        <div class="error_box" v-show="errIndex.length">
            <div class="error_title">
              <span class="text">以下地址無效或金额無效無法發送</span>
              <div class="delete_box" @click="removeInputAdd">
                <img src="../assets/img/a3.png" alt="" srcset="">一鍵刪除
              </div>
            </div>
            <div>
              <span v-for="(item,index) in errIndex" :key="index">第{{item.index}}行<span v-show="index != errIndex.length-1">、</span></span>無效
            </div>
          </div>
        <div v-show="stepTabIndex == 1" class="tab_ctn">
          <uploadExcel
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          ></uploadExcel>
        </div>
      </div>
      <div class="base_btn2" @click="nextStep">下一步</div>
    </my-scroll>
    </div>
    <div class="main_box" v-show="step == 1">
      <my-scroll>
      <div class="info_box">
        <div class="base_title base_title_wallet mt20">轉賬信息</div>
        <div>
          <div class="need_item">
            <div class="num">{{ uploadResult.length }}</div>
            <div class="card_label">地址總數</div>
          </div>
          <div class="need_item">
            <div class="num">{{ tradeNum }}</div>
            <div class="card_label">交易總數</div>
          </div>
          <div class="need_item">
            <div class="num">{{ tradeToken }}</div>
            <div class="card_label">代幣發送總數</div>
          </div>
          <div class="need_item">
            <div class="num">{{ tokenBalance }}</div>
            <div class="card_label">代幣餘額</div>
          </div>
          <div class="need_item">
            <div class="num">{{ balance }}</div>
            <div class="card_label">
              你的{{ chainObj[selectChain].Symbol }}餘額
            </div>
          </div>
          <div class="need_item">
            <div class="num align_center">
              {{ formatDecimal(gasPrice * gasMulti * tradeNum * 2)
              }}{{ chainObj[selectChain].Symbol
              }}<Tooltip
                max-width="300"
                :content="`按单次${uploadResult.length}个地址计算`"
                placement="left"
              >
                <img src="../assets/img/question.png" class="question" srcset />
              </Tooltip>
            </div>
            <div class="card_label">預估費用</div>
          </div>
          <div class="need_item" v-show="tokenSymbol">
            <div class="num">{{ tradeToken }}</div>
            <div class="card_label">需要授權額度</div>
          </div>
          <div class="need_item" v-show="tokenSymbol">
            <div class="num">
              {{ allowanceToken > 1000000000 ? "無限數量" : allowanceToken }}
            </div>
            <div class="card_label">當前授權額度</div>
          </div>
          <div class="need_item" v-show="tokenSymbol">
            <div class="num">&nbsp;</div>
            <div class="card_label">&nbsp;</div>
          </div>
        </div>
      </div>
      <div class="error_title2 mt20">
        <div class="base_title base_title_wallet" style="margin-bottom: 0">
          交易信息
        </div>
        <div>
          <!-- 手续费不足 -->
          <div
            class="tips_con"
            v-show="
              tokenSymbol &&
              parseFloat(balance) <
                parseFloat(formatDecimal(gasPrice * gasMulti * tradeNum * 2))
            "
          >
            <img src="../assets/img/error.png" srcset="" />
            {{ chainObj[selectChain].Symbol }}餘額不足,至少需要
            {{ formatDecimal(gasPrice * gasMulti * 2 * tradeNum) }}
            {{ chainObj[selectChain].Symbol }}
          </div>
          <div
            class="tips_con"
            v-show="
              !tokenSymbol &&
              parseFloat(balance) <
                parseFloat(formatDecimal(gasPrice * gasMulti * tradeNum * 2))
            "
          >
            <img src="../assets/img/error.png" srcset="" />
            {{ chainObj[selectChain].Symbol }}餘額不足,至少需要
            {{
              numAdd(formatDecimal(gasPrice * gasMulti * 2 * tradeNum), tradeToken)
            }}
            {{ chainObj[selectChain].Symbol }}
          </div>
          <!-- 余额不足 -->
          <div
            class="tips_con"
            v-show="
              (tokenSymbol && parseFloat(tokenBalance) < parseFloat(tradeToken)) ||
              (!tokenSymbol && parseFloat(balance) < parseFloat(tradeToken))
            "
          >
            <img src="../assets/img/error.png" srcset="" />
            {{
              tokenSymbol ? tokenSymbol : chainObj[selectChain].Symbol
            }}餘額不足,至少需要 {{ tradeToken }}
            {{ tokenSymbol ? tokenSymbol : chainObj[selectChain].Symbol }}
          </div>
        </div>
      </div>
      <!-- 地址数量列表 -->
      <div class="address_list">
        <div class="both_side">
          <div class="card_label">地址</div>
          <div class="card_label">數量</div>
        </div>
        <div class="trade_box">
          <div
            class="trade_item"
            v-for="(item, index) in uploadResult"
            :key="index"
          >
            <span class="add_box add_box_pc"
              >{{ item.address
              }}<img
                src="../assets/img/close2.png"
                srcset=""
                v-show="uploadResult.length > 1"
                @click="removeAdd(index)"
            /></span>
            <span class="add_box add_box_mb"
              >{{ showAccount2(item.address)
              }}<img
                src="../assets/img/close2.png"
                srcset=""
                v-show="uploadResult.length > 1"
                @click="removeAdd(index)"
            /></span>
            <div class="amount_text">
              {{ item.amount }}
              {{ tokenSymbol ? tokenSymbol : chainObj[selectChain].Symbol }}
            </div>
          </div>
        </div>
      </div>
      <!-- 交易速度 -->
      <div class="trade_speed">
        <div class="label">交易速度</div>
        <div style="padding: 0 20px">
          <vue-slider
            :min="1"
            :interval="0.1"
            :tooltip-formatter="formatter1"
            v-model="gasMulti"
            height="8px"
          ></vue-slider>

          <div class="lable_box">
            <span>慢🚶‍♂️</span><span>快 🚲</span
            ><span style="text-align: right">極速 ✈</span>
          </div>
        </div>
      </div>
      <div class="main_btns">
        <div class="btn btn_bg" @click="lastStep">上一步</div>
        <div class="btn btn_bd" @click="nextStep">下一步</div>
      </div>
    </my-scroll>
    </div>
    <div class="main_box" v-show="step == 2">
      <div class="send_progress" v-show="isSend">
        總共需要發送 {{ tradeNum }} 次,正在發送第 {{ currentSend }} 次
      </div>
      <div class="send_progress" v-show="!isSend && sendNum.length">
        總共需要發送 {{ tradeNum }} 次,還需要發送 {{ sendNum.length }} 次
      </div>
      <div class="send_progress">
        {{
          sendNum.length == 0
            ? "完成交易"
            : !isSend
            ? "等待確認交易"
            : "正在交易"
        }}
      </div>
      <div class="send_progress" v-show="isPerson">
        <span
          v-for="(item, index) in sendResult"
          :key="index"
          @click="sendTrade(index)"
          v-if="!sendResult[item]"
          class="btn_step"
          >发送第{{ index }}笔</span
        >
      </div>
      <div class="hash_box" v-show="sendHash.length > 0">
        <a
          :href="`${chainObj[selectChain].Browser}tx/${item}`"
          target="_blank"
          rel="noopener noreferrer"
          v-for="(item, index) in sendHash"
          :key="index"
          >{{ item }}</a
        >
      </div>
      <div class="main_btns">
        <div class="btn btn_bg" @click="lastStep">上一步</div>
        <div class="btn btn_bd" @click="showSend">嘗試人工</div>
      </div>
    </div>
    <Modal
      v-model="amountModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
      <div class="modal_header">
        <div class="modal_title">
          <img
            src="../assets/img/wallet_num.png"
            alt=""
            srcset=""
          />可自動在每個地址後面添加數量
        </div>
        <img
          src="../assets/img/vipclose.png"
          class="close_icon"
          srcset
          @click="amountModal = false"
        />
      </div>
      <div class="align_center">
        <div class="base_input" style="flex: 1">
          <input type="text" v-model="amountNum" :placeholder="$t('tool.14')" />
        </div>
        <span
          class="base_btn"
          style="width: 100px; margin-left: 20px"
          @click="confirmNum"
          >確認</span
        >
      </div>
    </Modal>
  </div>
</template>

<script>
function numAdd(num1, num2) {
  var baseNum, baseNum1, baseNum2;
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  var precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2; //精度
  return ((num1 * baseNum + num2 * baseNum) / baseNum).toFixed(precision);
}
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    //先获取到精确的小数位
    var fixed = (''+num).match(/\d+$/)[0];
    //拿到保留指定的小数
    return new Number(num).toFixed(fixed);
}

import {
  MultiSend_ABI,
  VipSale_ABI,
  ERC20_ABI,
  VipSale,
  ZERO_ADDRESS,
  MAX_INT,
} from "../config";
import BN from "bn.js";
import uploadExcel from "@/components/uploadExcel";
import JsonEditor from "@/components/JsonEditor";
import { chainObj } from "../config";
import clip from "../utils/clipboard";
import {
  showFromWei,
  showFromWei2,
  showAccount2,
  multiply,
  formatDecimal,
  toWei,
  fromWei,
  clickOutside
} from "../utils";
export default {
  directives: {clickOutside},
  components: { uploadExcel, JsonEditor },
  props: {
    address: {
      type: String,
    },
    chainId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      formatter1: '{value}倍',
      status: false,
      step: 0,
      funStatus: false,
      stepTabIndex: 0,
      amountModal: false,
      amountNum: 0,

      tradeNum: 0,
      tradeToken: 0,
      gasPrice: null,
      gasMulti: 1,
      gasLimit: "1000000",
      allowanceToken: 0,
      currentSend: 1,
      isSend: false,
      isPerson: false,
      sendResult: {},
      sendList: [],
      sendHash: [],

      show1: false,
      show3: false,
      selectChain: "BSC",
      selectChainId: 56,
      selectChainName: "Binance Smart Chain",
      selectChainRPC: "https://bsc-dataseed1.binance.org/",
      selectMultiSend: "0xBE9f4f99E4FE188428188763EcD53715d713D226",
      selectToken: {},
      chainObj,
      Tokens: chainObj["BSC"].Tokens,
      list: [],
      downloadLoading: false,
      shareUrl: "111",
      jsonValue: "",
      uploadResult: [],
      CheckVipRpc: "https://bsc-dataseed1.binance.org/",
      balance: 0,
      tokenBalance: 0,
      tokenAddress: "",
      tokenDecimals: "",
      tokenSymbol: "",
      selected: true,
      isError: false,
      errHtml: "",
      errIndex: [],
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj);
    },
    sendNum() {
      return Object.values(this.sendResult).filter((item) => item == false);
    },
    isVip() {
      return this.$store.state.app.isVip;
    },
  },
  watch: {
    address() {
      if (this.address) {
        this.getTime();
      }
    },
  },
  mounted() {
    if (this.address) {
      this.getTime();
    }
  },
  methods: {
    formatDecimal,
    async getTime() {

      var Web3 = require("web3");
      let rpcUrl = this.selectChainRPC
      if(this.selectChain == 'OP') {
        rpcUrl = 'https://ethereum.publicnode.com'
      }
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));

      let gasPrice = await myWeb3.eth.getGasPrice();
      
      this.gasPrice = multiply(myWeb3.utils.fromWei(gasPrice), this.gasLimit)
      console.log("gasPrice",this.gasPrice)
      if(this.selectChain == 'OP') {
        this.gasPrice = multiply(this.gasPrice,0.05)
      }
    },
    handleScroll() {
      this.show1 = false;
      this.show3 = false;
    },
    numAdd,
    showAccount2,
    addressInput() {
      console.log(this.selectToken.Symbol);
      if (this.selectToken.Symbol && this.selectToken.Symbol.length > 5) {
        this.tokenAddress = this.selectToken.Symbol;
        this.show3 = false;
        console.log(this.selectToken.Symbol);
        this.confirmToken();
      }
    },
    //转账主币
    async sendETH(cur) {
      console.log("正在发送第几个", cur + 1);
      this.currentSend = cur + 1;
      this.isSend = true;
      let account = this.address;
      this.message = this.$message({
        type: "loading",
        message: "loading",
        duration: 0,
      });
      try {
        const web3 = window.web3;
        const MultiSendContract = new web3.eth.Contract(
          MultiSend_ABI,
          this.selectMultiSend
        );
        let sendObj = this.sendList[cur];
        let tos = [];
        let amounts = [];
        let wallets = this.uploadResult.slice(sendObj.start, sendObj.end + 1);
        let length = wallets.length;
        //总转账金额
        let value = new BN(0);
        for (let index = 0; index < length; index++) {
          tos.push(wallets[index].address);
          //转账精度处理
          let amount = toWei(wallets[index].amount, 18);
          amounts.push(amount);
          value = value.add(amount);
        }
        var gasPrice = await web3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));
         let gas = new BN(this.gasLimit, 10);
        var transaction = await MultiSendContract.methods
          .sendETHs(tos, amounts)
          .send({
            from: account,
            value: value,
            // gas: web3.utils.toHex(gas),
            gasPrice: web3.utils.toHex(gasPrice),
          });
        console.log("转账结果", transaction);
        if (transaction.status) {
          this.sendResult[this.currentSend] = true;
          this.sendHash.push(transaction.transactionHash);
          this.$message(this.$t("lang.success1"));
        } else {
          this.$message.error(this.$t("lang.fail1"));
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        this.message.close();
        this.isSend = false;
      }
    },
    //批量转账代币
    async sendToken(cur) {
      console.log("正在发送第几个", cur);
      this.currentSend = cur + 1;
      this.isSend = true;
      let account = this.address;

      if (!this.tokenSymbol) {
        this.$message.error(
          "請輸入正确的代币合约地址，并点击确定按钮获取代币信息"
        );
        return;
      }
      let sendObj = this.sendList[cur];
      let tos = [];
      let amounts = [];
      let wallets = this.uploadResult.slice(sendObj.start, sendObj.end + 1);
      let length = wallets.length;
      if (0 == length) {
        this.$message.error("请导入转账接收地址列表");
        return;
      }
      let tokenDecimals = this.tokenDecimals;
      for (let index = 0; index < length; index++) {
        tos.push(wallets[index].address);
        amounts.push(toWei(wallets[index].amount, tokenDecimals));
      }
      this.message = this.$message({
        type: "loading",
        message: "loading",
        duration: 0,
      });
      let tokenAddress = this.tokenAddress;
      try {
        const web3 = window.web3;
        const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
        let allowance = await tokenContract.methods
          .allowance(account, this.selectMultiSend)
          .call();
        allowance = new BN(allowance, 10);
        if (allowance.isZero()) {
          let transaction = await tokenContract.methods
            .approve(this.selectMultiSend, MAX_INT)
            .send({ from: account });
          if (!transaction.status) {
            this.$message.error(this.$t("lang.a4"));
            return;
          }
        }
        var gasPrice = await web3.eth.getGasPrice();
        gasPrice = new BN(gasPrice, 10);
        //gas倍数
        let gasMulti = this.gasMulti;
        if (!gasMulti) {
          gasMulti = 1;
        }
        gasMulti = parseFloat(gasMulti);
        gasMulti = parseInt(gasMulti * 100);
        gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));
        let gas = new BN(this.gasLimit, 10);
        const MultiSendContract = new web3.eth.Contract(
          MultiSend_ABI,
          this.selectMultiSend
        );
        var transaction = await MultiSendContract.methods
          .sendTokens(tokenAddress, tos, amounts)
          .send({ from: account, 
            // gas: web3.utils.toHex(gas), 
            gasPrice: web3.utils.toHex(gasPrice) });
        console.log("转账结果", transaction);
        if (transaction.status) {
          this.sendResult[this.currentSend] = true;
          this.sendHash.push(transaction.transactionHash);
          this.$message(this.$t("lang.success1"));
        } else {
          this.$message.error(this.$t("lang.fail1"));
        }
      } catch (e) {
        console.log("e", e);
      } finally {
        this.message.close();
        this.isSend = false;
      }
    },
    //确定代币合约地址
    async confirmToken() {
      let tokenAddress = this.tokenAddress;
      if (!tokenAddress) {
        this.$message.error("請輸入正确的代币合约地址");
        return;
      }
      this.message = this.$message({
        type: "loading",
        message: "loading",
        duration: 0,
      });
      try {
        const web3 = window.web3;
        const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
        let tokenSymbol = await tokenContract.methods.symbol().call();
        let tokenDecimals = await tokenContract.methods.decimals().call();
        tokenDecimals = parseInt(tokenDecimals);
        console.log(tokenSymbol, tokenDecimals);

        this.tokenDecimals = tokenDecimals;
        this.tokenSymbol = tokenSymbol;
        this.selectToken.Symbol = tokenSymbol;
        this.getWalletBalance();
      } catch (e) {
        console.log("e", e);
      } finally {
        this.message.close();
      }
    },
    //获取钱包主币、代币余额
    async getWalletBalance() {
      let account = this.address;
      if (!account) {
        return;
      }
      try {
        const myWeb3 = window.web3;

        //获取主币余额
        let balance = await myWeb3.eth.getBalance(account);
        let showBalance = showFromWei(balance, 18, 4);

        let tokenSymbol = this.tokenSymbol;
      console.log( this.tokenAddress)
        let showTokenBalance = 0;
        if (tokenSymbol) {
          //获取代币余额
          const tokenContract = new myWeb3.eth.Contract(
            ERC20_ABI,
            this.tokenAddress
          );
          let tokenBalance = await tokenContract.methods
            .balanceOf(account)
            .call();
          showTokenBalance = showFromWei(tokenBalance, this.tokenDecimals, 4);
        } else {
          showTokenBalance = showBalance;
        }

        console.log(showBalance, showTokenBalance);
        this.balance = showBalance;
        this.tokenBalance = showTokenBalance;
      } catch (e) {
        console.log("e", e);
      } finally {
      }
    },

    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }
      this.$message.error("Please do not upload files larger than 1m in size.");
      return false;
    },
    handleSuccess({ header, results }) {
      console.log(header, results);
      if (results.length > 0) {
        this.uploadResult = results;
        this.jsonValue = results
          .map((item) => item.address + "," + item.amount)
          .join("\n");
        this.stepTabIndex = 0;
      }
    },
    addAdd() {
      this.uploadResult.push({
        address: "",
        amount: "",
      });
    },
    removeAdd(index) {
      this.uploadResult.splice(index, 1);
      this.jsonValue = this.uploadResult
        .map((item) => item.address + "," + item.amount)
        .join("\n");
    },
    removeInputAdd() {
      let wallets = this.uploadResult
      this.errIndex.forEach((item) => {
        let index = wallets.findIndex(wallet => wallet.address == item.address)
        wallets.splice(index, 1)
      });
      this.uploadResult = wallets
      this.jsonValue = this.uploadResult
        .map((item) => item.address + "," + item.amount)
        .join("\n");
      this.errIndex = []
    },
    changeInput() {
      this.jsonValue = this.uploadResult
        .map((item) => item.address + "," + item.amount)
        .join("\n");
    },
    confirmNum() {
      if (typeof parseInt(this.amountNum) === "number") {
        if (this.uploadResult.length > 0) {
          this.uploadResult.forEach((item) => (item.amount = this.amountNum));
          this.jsonValue = this.uploadResult
            .map((item) => item.address + "," + item.amount)
            .join("\n");
          this.amountModal = false;
        } else {
          this.$message.error("請先輸入地址");
        }
      } else {
        this.$message.error("請輸入数量");
      }
    },
    uploadData() {
      this.jsonValue =
        "0xd41b9AC5767cd90AbC7C997730591cBb7223D3D9,1\n0xB3CAB50183a7e414F3615eF1394384C203d8A8F5,2\n0x20b7819894b14C46Cb3c10704C6A5f13C36b7983,3";
    },
    // 监听文本域的并检查正确性
    handleJsonChange(val) {
      let result = val.split("\n");
      let result2 = result.map((item) => {
        var obj = {};
        obj.address = item.split(",")[0];
        obj.amount = item.split(",")[1];
        return obj;
      });
      this.uploadResult = result2;
      console.log(result2);
      let errIndex = [];
      this.uploadResult.forEach((item, index) => {
        if (item.address && item.address.length != 42 || !item.amount || item.amount == '0') {
          errIndex.push({
                index: index+1,
                address: item.address
          });
        }
        item.amount = getFullNum(item.amount)
      });
      if (errIndex.length) {
        this.isError = true;
      } else {
        this.isError = false;
      }
      this.errIndex = errIndex;
    },
   
    showModal() {
      this.amountModal = true;
    },
    changeFun(status) {
      this.funStatus = status;
    },
    async addChain() {
      try {
        let that = this;
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.numberToHex(that.selectChainId) }], //
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: web3.utils.numberToHex(that.selectChainId),
                  chainName: that.selectChainName,
                  rpcUrls: [that.selectChainRPC] /* ... */,
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },
    handleDownload() {
      this.downloadLoading = true;
      import("@/utils/Export2Excel").then((excel) => {
        const tHeader = ["address", "mnemonic", "privateKey"];
        const data = this.list;
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "address_list",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    clipboardSuccess(event, text) {
      var that = this;
      clip(text, event, function () {
        that.$Message.success(that.$t("lang.success1"));
      });
    },
    switchChain(obj) {
      console.log(obj);
      this.selectChain = obj.chain;
      this.selectChainId = obj.ChainId;
      this.selectChainName = obj.chainName;
      this.selectChainRPC = obj.RPC;
      this.selectMultiSend = obj.MultiSend;
      this.gasMulti = 1;
      this.Tokens = this.chainObj[obj.chain].Tokens;
      this.tokenAddress = "";
      this.show1 = false;
      this.getTime()
    },
    //选择交易币种
    selToken(index) {
      if (this.selectToken.address == this.Tokens[index].address) return;
      this.selectToken = JSON.parse(JSON.stringify(this.Tokens[index]));
      this.tokenAddress = this.Tokens[index].address;
      if (this.tokenAddress) {
        this.confirmToken();
      } else {
        this.tokenSymbol = ''
        this.getWalletBalance();
      }
      this.show3 = false;
    },
    removeAdd(index) {
      this.uploadResult.splice(index, 1);
      console.log(this.uploadResult);
      let len = this.uploadResult.length;
      this.tradeNum = parseInt(len / 200) + (len % 200 > 0 ? 1 : 0);
      this.tradeToken = this.uploadResult.reduce(
        (sum, e) => numAdd(sum, Number(e.amount || 0)),
        0
      );
      let sendList = [],
        sendResult = {};
      for (let i = 1; i <= this.tradeNum; i++) {
        sendResult[i] = false;
        var end = 200 * i - 1;
        if (end > len - 1) end = len - 1;
        sendList.push({
          start: 200 * (i - 1),
          end,
        });
      }
      this.sendList = sendList;
      this.sendResult = sendResult;
      console.log(sendList, sendResult);
    },
    showSend() {
      this.isPerson = true;
    },
    async nextStep() {
      if (this.step == 0) {
        if (!this.isVip) {
          this.$message.error("僅限VIP使用");
          return;
        }
        console.log(chainObj);
        console.log(this.chainId, this.selectChainId);
        if (this.chainId != this.selectChainId) {
          this.$message.error("請先切換錢包網絡");
          return;
        }
        if (!this.selectToken.Symbol) {
          this.$message.error("請選擇代幣");
          return;
        }
        let len = this.uploadResult.length;
        if (len == 0) {
          this.$message.error("請輸入收款地址");
          return;
        }
        if (this.isError) {
          return;
        }
        this.tradeNum = parseInt(len / 200) + (len % 200 > 0 ? 1 : 0);
        this.tradeToken = this.uploadResult.reduce(
          (sum, e) => numAdd(sum, Number(e.amount || 0)),
          0
        );
        let sendList = [],
          sendResult = {};
        for (let i = 1; i <= this.tradeNum; i++) {
          sendResult[i] = false;
          var end = 200 * i - 1;
          if (end > len - 1) end = len - 1;
          sendList.push({
            start: 200 * (i - 1),
            end,
          });
        }
        this.sendList = sendList;
        this.sendResult = sendResult;
        console.log(sendList, sendResult);

        if (this.tokenSymbol) {
          let web3 = window.web3;
          let tokenContract = new web3.eth.Contract(
            ERC20_ABI,
            this.tokenAddress
          );
          let allowance = showFromWei(
            await tokenContract.methods
              .allowance(this.address, this.selectMultiSend)
              .call(),
            this.tokenDecimals,
            2
          );
          console.log(allowance);
          this.allowanceToken = allowance;
          // allowanceToken
        }
        this.step = 1;
      } else if (this.step == 1) {
        if (this.tokenSymbol) {
          if (this.allowanceToken == 0) {
            let web3 = window.web3;
            let tokenContract = new web3.eth.Contract(
              ERC20_ABI,
              this.tokenAddress
            );
            let amount = this.selected
              ? MAX_INT
              : toWei(this.tradeToken, this.tokenDecimals);
            let transaction = await tokenContract.methods
              .approve(this.selectMultiSend, amount)
              .send({ from: this.address });
            if (!transaction.status) {
              this.$message.error(this.$t("lang.a4"));
              return;
            }
            this.allowanceToken = this.selected
              ? showFromWei(MAX_INT, 18, 0)
              : this.tradeToken;
            return;
          }
          if (parseFloat(this.tokenBalance) < parseFloat(this.tradeToken)) return;
          if (
            parseFloat(this.balance) <
              parseFloat(formatDecimal(this.gasPrice * this.gasMulti * this.tradeNum * 2))
          )
            return;
          // this.sendToken();
        } else {
          
          if (parseFloat(this.balance) < parseFloat(this.tradeToken)) return;
          // this.sendETH();
        }
        this.step = 2;
        if (this.tokenSymbol) {
          for (let i = 0; i < this.tradeNum; i++) {
            await this.sendToken(i);
          }
        } else {
          for (let i = 0; i < this.tradeNum; i++) {
            await this.sendETH(i);
          }
        }
      } else if (step == 2) {
        // if(this.tokenSymbol) {
        //   for(let i = 0; i < this.tradeNum; i++) {
        //     console.log(this)
        //     await this.sendToken();
        //   }
        // } else {
        //   for(let i = 0; i < this.tradeNum; i++) {
        //     console.log(this)
        //     await this.sendETH();
        //   }
        // }
      }
    },
    async sendTrade(i) {
      if (this.tokenSymbol) {
        await this.sendToken(i - 1);
      } else {
        await this.sendETH(i - 1);
      }
    },
    lastStep() {
      this.getWalletBalance();
      this.isPerson = false;
      this.sendHash = [];
      --this.step;
    },
  },
};
</script>

<style lang="less">
@import "../assets/pc/transfer.less";
@import "../assets/mobile/transfer.less";
</style>