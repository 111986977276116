<template>
  <div>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls, .csv"
      @change="handleClick"
    />
    <div
      class="drop"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
      @click="handleUpload"
    >
      <img src="../assets/img/download.png" class="download" srcset="" />
      <div class="download_text">上傳文件或拖動文件至此</div>
     <a target="_blank" class="upload_link" href="/example.xlsx">下载例子</a>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
function myTrim(x) {
  return String(x).replace(/^\s+|\s+$/gm, "");
}
export default {
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function, // eslint-disable-line
  },
  data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null,
      },
    };
  },
  methods: {
    generateData({ header, results }) {
      console.log(header, results)
      let resultNew = [];
      if (header.length) {
        if (header.includes('address')) {
          results.map((item) => {
            let address = myTrim(item['address'])
            let amount = ''
            if(header.includes('amount')) {
              amount = myTrim(item['amount'])
            }
            resultNew.push({
              address,
              amount,
            });
          });
        } else {
          resultNew.push({
            address: myTrim(header[0]),
            amount: header[1],
          });
          results.map((item) => {
            resultNew.push({
              address: myTrim(item[header[0]]),
              amount: item[header[1]],
            });
          });
        }
      }
      this.excelData.header = header;
      this.excelData.results = resultNew;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error("Only support uploading one file!");
        return;
      }
      const rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error(
          "Only supports upload .xlsx, .xls, .csv suffix files"
        );
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
  },
};
</script>

<style lang="less">
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border: 1px solid #d0e2e4;
  background: rgba(255, 255, 255, 0.1);
  height: 192px;
  font-size: 12px;
  border-radius: 4px;
  .download {
    width: 68px;
  }
  .download_text {
    font-size: 12px;
    font-weight: 500;
    color: rgba(34, 34, 34, 0.5);
    line-height: 14px;
    margin-top: 16px;
  }
  .upload_link {
    font-size: 12px;
    font-weight: bold;
    color: #2980ff;
    line-height: 14px;
    margin-top: 8px;
  }
}
@media screen and (max-width: 768px) {
  .drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border: 1px solid #d0e2e4;
  background: rgba(255, 255, 255, 0.1);
  height: 192px;
  font-size: 12px;
  border-radius: 4px;
  .download {
    width: 68px;
  }
  .download_text {
    font-size: 12px;
    font-weight: 500;
    color: rgba(34, 34, 34, 0.5);
    line-height: 14px;
    margin-top: 16px;
  }
  .upload_link {
    font-size: 12px;
    font-weight: bold;
    color: #2980ff;
    line-height: 14px;
    margin-top: 8px;
  }
}
}
</style>